import { injectBrowserValues } from '@newcross-tech/app-config-core';

import { AppConfig } from '@src/app-config';

bootstrap();

async function bootstrap() {
  const { values: appConfigValues } = await injectBrowserValues();

  AppConfig.initialize(appConfigValues);

  await import('./main');
}
